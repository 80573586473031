<script>
import {authMethods} from "@/state/helpers";
import appConfig from "../../../app.config";
import animationData from "@/components/widgets/rhvddzym.json";
import WelcomTitleLogin from "@/views/account/WelcomTitleLogin.vue";
import AuthFooter from "@/views/account/AuthFooter.vue";
import globalMixins from "@/mixins/globalMixins";
import crudMixin from "@/mixins/crudMixin";

export default {
  components: {AuthFooter, WelcomTitleLogin,},
  mixins: [globalMixins(), crudMixin()],
  page: {
    title: "Réinitialisation du Mot de Passe",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      password: "",
      password_confirmation: "",
      error: null,
      tryingToReset: false,
      isResetError: false,
      errorResponse: undefined,
      successResponse: undefined,
      defaultOptions: {animationData: animationData},
      token: '',
      verified: false,
      submitted: false,
      showPassword: false,
     // validationErrors: undefined,
    };
  },
  methods: {
    ...authMethods,
    tryToReset() {
      this.successResponse = undefined;
      this.errorResponse = undefined;
      this.submitted = true;
      this.find(this.$route.params.token)
          .then((data) => {
            this.reset(data.email);
          }).catch((error) => {
        switch (error.response.status) {
          case 404 :
            this.showToast('error', 'Token invalide')
            this.errorResponse = "Lien de réinitialisation du mot de passe invalide";
            break;
          case 422 :
            this.validationErrors = error.response.data.errors
            break;
        }
      }).finally(() => {
        this.submitted = false;
        this.submitting = false;
      })
    },
    verify(module) {
      this.$store.dispatch(module, this.token).then((user) => {
        this.verified = true;
        this.email = user.username || user.email
      }).catch((error) => {
        switch (error.response.status) {
          case 500 :
            this.$swal('Erreur', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 404 :
            this.validationErrors = {errors: [{errors: error.response.data.error}]}
            this.$swal('Erreur', "Oups! Lien d'invitation invalide", 'error')
            break;
          default:
            this.validationErrors = error.response.data.errors
            break;
        }
      });
    },
    reset(email) {
      this.submitted = true;
      this.resetPassword({email: email, password: this.password, password_confirmation: this.password_confirmation})
          .then(() => {
            this.successResponse = "Mot de passe réinitialisé avec succès !";
            this.showToast('success', 'Mot de passe réinitialisé avec succès !')
            let Self = this;
            setTimeout(function () {
              Self.$router.push({name: 'login'})
            }, 3000)

          }).catch((error) => {
        this.submitted = false;
        switch (error.response.status) {
          case 404 :
            this.showToast('error', 'Utilisateur introuvable')
            this.errorResponse = "Nous ne parvenons pas à retrouver un utilisateur avec l'email saisi.";
            break;
          case 400 :
            this.showToast('error', 'Email ou mot de passe inccorect')
            break;
          case 422 :
            this.validationErrors = error.response.data.errors
        }
      })
    }
  },
  mounted() {
    let module = this.$route.name === 'invitation' ? "auth/verify" : "auth/find";

    this.token = this.$route.params.token;
    this.verify(module)

  }
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
    </div>

    <div class="auth-page-content">
      <b-container>

        <welcom-title-login/>

        <b-row class="justify-content-center">
          <b-col md="8" lg="6" xl="5">
            <b-card no-body class="mt-4">
              <b-card-body class="p-4">
                <validation-errors :error="validationErrors"/>
                <div class="text-center mt-2">
                  <h5 class="text-primary" v-if="$route.name==='invitation'">Configuration du mot de passe</h5>
                  <h5 class="text-primary" v-else>Réinitialisation du mot de passe</h5>
                  <b-alert fade show v-if="successResponse" variant="info">{{ successResponse }}</b-alert>
                  <b-alert fade show variant="danger" v-if="errorResponse">{{ errorResponse }}</b-alert>
                </div>

                <div class="p-2">
                  <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{ error }}</b-alert>
                  <form @submit.prevent="tryToReset">
                    <div class="mb-4">
                      <label class="form-label">Nouveau mot de passe</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input
                            :type="showPassword?'text':'password'"
                            v-model="password" class="form-control pe-5"
                            placeholder="Mot de passe"
                            :class="{ 'is-invalid':  validationErrors?.password}"
                            id="password-input" />
                        <b-button @click.prevent="showPassword = !showPassword" variant="link" class="position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button" id="password-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </b-button>
                        <span v-if="hasError('password')" class="invalid-feedback" role="alert">
                        <strong>{{ validationErrors.password[0] }}</strong>
                      </span>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label class="form-label">Confirmer le mot de passe</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input :type="showPassword?'text':'password'"
                               v-model="password_confirmation"
                               :class="{ 'is-invalid':  validationErrors?.password_confirmation}"
                               class="form-control pe-5"
                               placeholder="Confirmer le mot de passe"
                               id="password_confirmation" />
                        <b-button @click.prevent="showPassword = !showPassword" variant="link" class="position-absolute end-0 top-0 text-decoration-none text-muted"
                                  type="button" id="password_confirmation-addon">
                          <i class="ri-eye-fill align-middle"></i>
                        </b-button>
                        <span v-if="hasError('password_confirmation')" class="invalid-feedback" role="alert">
                        <strong>{{ validationErrors.password_confirmation[0] }}</strong>
                      </span>
                      </div>
                    </div>

                    <div class="text-center mt-4">
                      <b-button :disabled="submitted || !verified" :loading="submitted" variant="success" class="w-100"
                                type="submit">
                        Valider
                      </b-button>
                    </div>
                  </form>
                </div>
              </b-card-body>
            </b-card>
            <div class="mt-4 text-center" v-if="$route.name !== 'invitation'">
              <p class="mb-0">
                Attendez, Je me rapelle...
                <router-link to="/login" class="fw-semibold text-primary text-decoration-underline">
                  Se connecter
                </router-link>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <auth-footer/>
  </div>
</template>
