import {show, store} from "@/services/resources";

    export const  state = {
        editData:undefined,
    }
    export  const mutations =  {
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    }
    export  const  actions =  {
        getMessages(_,orderId){
            return new Promise((resolve,reject)=>{
                show('v1/messages',orderId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getServiceMessages(_,clientId){
            return new Promise((resolve,reject)=>{
                show('v1/cs-messages',clientId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        sendMessage(_,data){
            return new Promise((resolve,reject)=>{
                store('v1/messages',data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    }
     export const getters =  {

    }

