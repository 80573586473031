import { validateShootingSchedule, rejectShootingSchedule } from "@/services/resources"

export const actions = {
    validate(_,data){
        return new Promise((resolve,reject)=>{
            validateShootingSchedule(data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    reject(_,data){
        return new Promise((resolve,reject)=>{
            rejectShootingSchedule(data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
}