import auth from "@/router/middlewares/auth";

export default [
    //customs
    {
        path: '/roles',
        name: 'roles.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/roles/index.vue')
    },
    {
        path: '/roles/:id/edit',
        name: 'roles.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/roles/create.vue')
    },
    {
        path: '/roles/create',
        name: 'roles.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/roles/create.vue')
    },
    {
        path: '/politics',
        name: 'politics.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Politiques", authRequired: true,
        },
        component: () => import('../../views/configs/politics/index.vue')
    },
    {
        path: '/politics/:id',
        name: 'politics.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Politiques", authRequired: true,
        },
        component: () => import('../../views/configs/politics/show.vue')
    },
    {
        path: '/politics/:id/edit',
        name: 'politics.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Politiques", authRequired: true,
        },
        component: () => import('../../views/configs/politics/create.vue')
    },
    {
        path: '/politics/create',
        name: 'roles.politics',
        meta: {
            middleware: [
                auth,
            ],
            title: "Politiques", authRequired: true,
        },
        component: () => import('../../views/configs/politics/create.vue')
    },
    {
        path: '/cities',
        name: 'cities.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Villes", authRequired: true,
        },
        component: () => import('../../views/configs/cities/index.vue')
    },
    {
        path: '/districts/:id?',
        name: 'districts.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Arrondissement", authRequired: true,
        },
        component: () => import('../../views/configs/arrondissements/index.vue')
    },
    {
        path: '/areas/:id?',
        name: 'areas.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Quartiers", authRequired: true,
        },
        component: () => import('../../views/configs/areas/index.vue')
    },
    {
        path: '/marques',
        name: 'marques.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Marques", authRequired: true,
        },
        component: () => import('../../views/marques/index.vue')
    },

    {
        path: '/gsms',
        name: 'gsms.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Opérateur GSM", authRequired: true,
        },
        component: () => import('../../views/configs/gsms/index.vue')
    },
    {
        path: '/fees',
        name: 'fees.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Frais de retrait", authRequired: true,
        },
        component: () => import('../../views/configs/fees/index.vue')
    },
    {
        path: '/fee-configs',
        name: 'fee-configs.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Configuration des frais", authRequired: true,
        },
        component: () => import('../../views/configs/fee-configs/index.vue')
    },
    {
        path: '/subscription-components',
        name: 'subscription-components.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Composante des packs", authRequired: true,
        },
        component: () => import('../../views/configs/subscription-components/index.vue')
    },
    {
        path: '/ingredients',
        name: 'ingredients.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Ingrédients", authRequired: true,
        },
        component: () => import('../../views/configs/ingredients/index.vue')
    },
    {
        path: '/problem-categories',
        name: 'problems.categories.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Catégories de problème", authRequired: true,
        },
        component: () => import('../../views/configs/problems/categories.vue')
    },
    {
        path: '/problem-categories/:id',
        name: 'problems.categories.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Catégorie de problème", authRequired: true,
        },
        component: () => import('../../views/configs/problems/category.vue')
    },
    {
        path: '/problems/types',
        name: 'problems.types.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Types de problème", authRequired: true,
        },
        component: () => import('../../views/configs/problems/types.vue')
    },
    {
        path: '/instructions',
        name: 'instructions.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Instructions", authRequired: true,
        },
        component: () => import('../../views/configs/instructions/index.vue')
    },
]
