import {assignOrder, changeStatus} from "@/services/resources";

    export const  state = {
        editData:undefined,
    }
    export  const mutations =  {
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    }
    export  const  actions =  {
        assignOrderToStaff(_,data){
            return new Promise((resolve,reject)=>{
                assignOrder(data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        changeStatus(_,data){
            return new Promise((resolve,reject)=>{
                changeStatus(data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    }
     export const getters =  {

    }

