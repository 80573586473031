import auth from "@/router/middlewares/auth";

const deliveryRoutes = [
    {
        path: "/delivery/orders/:id",
        name: "delivery.orders.show",
        meta: {
            middleware: [auth],
            title: "Détails Commandes",
            authRequired: true,
        },
        component: () => import("../views/delivery/orders/show.vue"),
    },
    {
        path: "/orders/find/merchants/:id",
        name: "find.merchants",
        meta: {
            middleware: [auth],
            title: "Marchands",
            authRequired: true,
        },
        component: () => import("../views/delivery/orders/PayMerchants.vue"),
    },
    {
        path: "/delivery/orders",
        name: "delivery.orders.index",
        meta: {
            middleware: [auth],
            title: "Historique des commandes",
            authRequired: true,
        },
        component: () => import("../views/delivery/orders/index.vue"),
    },
]

export default deliveryRoutes;
