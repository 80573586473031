<template>
 <router-view></router-view>
</template>
<script>

export default {
  name: 'App',
  components: {

  },
 methods : {
  checkNotificationPromise() {
   try {
     Notification.requestPermission().then();
   } catch (e) {
    return false;
   }
   return true;
  },

  askNotificationPermission() {
   // function to actually ask the permissions
   // Let's check if the browser supports notifications
   if (!('Notification' in window)) {
     console.log("This browser does not support notifications.");
   } else if (this.checkNotificationPromise()) {
    Notification.requestPermission().then(() => {
    });
   } else {
    Notification.requestPermission(() => {
     //console.log(permission)
    });
   }
  },
 },
  mounted() {
   this.askNotificationPermission();
  },

}
</script>
<style>
:root{
  --vs-font-size: 0.8125rem !important;
  --vs-line-height: 1.5;
}
.vs__search, .vs__search :focus{
  padding: 4px !important;
  color: #989797 !important;
}

   /* Define your transition styles */
 .fade-enter-active, .fade-leave-active {
   transition: opacity 0.5s ease;
 }

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Slide Height Transition */
.slide-height-enter-active, .slide-height-leave-active {
  transition: height 10s ease;
  overflow: hidden;
}

.slide-height-enter-from, .slide-height-leave-to {
  height: 0;
  opacity: 0;
}

.slide-height-enter-to, .slide-height-leave-from {
  height: auto;
  opacity: 1;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
