import { get} from "@/services/resources";
import {CONFIGS} from "@/services/apis";
import client from "@/state/axios/client";

    export const  state = {
        configs:undefined,
        config:undefined,
        createData:undefined,
        editData:undefined,

    }
    export  const mutations =  {
        SET_CONFIGS(state,payload){
            state.configs = payload
        },
        SET_CONFIG(state,payload){
            state.configs = {...payload}
        },


    }
    export  const  actions =  {
        get({commit},data){
            return new Promise((resolve,reject)=>{
                get(data).then((response)=>{
                    commit('SET_CONFIGS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update(_,data){
            return new Promise((resolve,reject)=>{
                client({
                    method : 'PUT',
                    url:`${CONFIGS}/${data.id}`,
                    data:data
                }).then(response => resolve(response))
                    .catch(error=>reject(error))
            })
        },

    }
     export const getters =  {

    }

