import {edit, get, create, store, update, destroy, disableUser} from "@/services/resources";
import {DELIVERERS, HOS, STAFF} from "@/services/apis";

    export const  state = {
        staff:undefined,
        createData:undefined,
        editData:undefined,
    }
    export  const mutations =  {
        SET_STAFF(state,payload){
            state.staff = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    }
    export  const  actions =  {
        get({commit}){
            return new Promise((resolve,reject)=>{
                get(STAFF).then((response)=>{
                    commit('SET_STAFF',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getHos({commit}){
            return new Promise((resolve,reject)=>{
                get(HOS).then((response)=>{
                    commit('SET_STAFF',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        getDeliverers({commit}){
            return new Promise((resolve,reject)=>{
                get(DELIVERERS).then((response)=>{
                    commit('SET_STAFF',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit},module){
            return new Promise((resolve,reject)=>{
                create(module).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(STAFF,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store(data){
            return new Promise((resolve,reject)=>{
                store(STAFF,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        disableUser(_,userId){
            return new Promise((resolve,reject)=>{
                disableUser(userId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update(data){
            return new Promise((resolve,reject)=>{
                update(STAFF,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy(itemId){
            return new Promise((resolve,reject)=>{
                destroy(STAFF,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    }
     export const getters =  {

    }

