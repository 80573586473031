import {update, destroy, show, store, get} from "@/services/resources";
import {CLIENTS} from "@/services/apis";

export const state = {
    clients:undefined,
    recharges:undefined,
    client:undefined,
    createData:undefined,
    editData:undefined,

};

export const getters = {
    todos: state => state.todos,
};

export const mutations = {
    SET_CLIENTS(state,payload){
        state.clients = {...payload}
    },
    SET_RECHARGES(state,payload){
        state.recharges = payload
    },
    SET_CLIENT(state,payload){
        state.client =  {...payload}
    },
    SET_CREATE_DATA(state,payload){
        state.createData = {...payload}
    },
    SET_EDIT_DATA(state,payload){
        state.editData = {...payload}
    },

};

export const actions = {
    getRecharges({commit},data){
        return new Promise((resolve,reject)=>{
            store("get-recharges",data).then((response)=>{
                commit('SET_RECHARGES',response.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    get({commit},data){
        return new Promise((resolve,reject)=>{
            get(data).then(({data})=>{
                commit('SET_CLIENTS',data)
                resolve(data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    show({commit},clientId){
        return new Promise((resolve,reject)=>{
            show(CLIENTS+"/details",clientId).then((response)=>{
                commit('SET_CLIENT',response.data)
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    update(_,data){
        return new Promise((resolve,reject)=>{
            update(CLIENTS,data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    destroy(_,itemId){
        return new Promise((resolve,reject)=>{
            destroy(CLIENTS,itemId).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

};
