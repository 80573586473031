import {get, store, show} from "@/services/resources";

    export const state = {
        pendingOrders : undefined,
        currentOrder :undefined,
        orders : undefined,
        order: undefined,
        page:undefined,
        search:undefined,
        loadingPage:false,
        per_page:10,
    };
    export const mutations =  {
        SET_LOADING_PAGE(state,payload){
            state.loadingPage = payload
        },
        SET_ORDERS(state,payload){
            state.orders = payload
        },
        SET_ORDER(state,payload){
            state.order = payload
        },
        SET_PENDING_ORDERS(state,payload){
            state.pendingOrders = payload
        },
        SET_CURRENT_ORDER(state,payload){
            state.currentOrder = payload
        },
    };
    export const actions =  {
        get({commit},url){
            return new Promise((resolve,reject)=>{
                get(url).then((response)=>{
                    commit('SET_LOADING_PAGE',false)
                    commit('SET_ORDER',undefined)
                    commit('SET_PENDING_ORDERS',response.data.orders)
                    commit('SET_CURRENT_ORDER',response.data.current)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        show({commit},data){
            return new Promise((resolve,reject)=>{
                show(data.resource, data.id).then((response)=>{
                    commit('SET_ORDER',response.data.order)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(data.resource,data.data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        // eslint-disable-next-line no-unused-vars
    };
    export const getters =  {

    };

