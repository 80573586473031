import moment from "moment/moment";

export const AppMixins = {
    resolve(path, obj) {
        return path.split('.').reduce(function(prev, curr) {
            return prev ? prev[curr] : null
        }, obj || self)
    },
    initCountriesFlags()
    {
        function loadCountryListData(datas) {
            var mainArray = Array.from(document.querySelectorAll("[data-input-flag]"));
            var flags = '';
            var arr = Array.from(datas);
            for (let index = 0; index < arr.length; index++) {
                flags += '<li class="dropdown-item d-flex">\
            <div class="flex-shrink-0 me-2"><img src="'+ arr[index]['flagImg'] + '" alt="country flag" class="options-flagimg" height="20"></div>\
                <div class="flex-grow-1">\
                <div class="d-flex"><div class="country-name me-1">'+ arr[index]['countryName'] + '</div><span class="countrylist-codeno text-muted">' + arr[index]['countryCode'] + '</span></div>\
            </div>\
            </li>';
            }
            // console.log(flags,mainArray)
            for (let i = 0; i < mainArray.length; i++) {
                mainArray[i].querySelector(".dropdown-menu-list").innerHTML = '';
                mainArray[i].querySelector(".dropdown-menu-list").innerHTML = flags;
                countryListClickEvent(mainArray[i]);
            }
        }
        function countryListClickEvent(item) {
            if (item.querySelector(".country-flagimg")) {
                var countryFlagImg = item.querySelector(".country-flagimg").getAttribute('src');
            }
            Array.from(item.querySelectorAll(".dropdown-menu li")).forEach(function (subitem) {
                var optionFlagImg = subitem.querySelector(".options-flagimg").getAttribute("src");
                subitem.addEventListener("click", function () {
                    var optionCodeNo = subitem.querySelector(".countrylist-codeno").innerHTML;
                    if (item.querySelector("button")) {
                        item.querySelector("button img").setAttribute("src", optionFlagImg);
                        if (item.querySelector("button span")) {
                            this.countryCode = optionCodeNo
                            item.querySelector("button span").innerHTML = optionCodeNo;
                        }
                    }
                });
                if (countryFlagImg == optionFlagImg) {
                    subitem.classList.add("active");
                }
            });
            // data option flag img with name
            Array.from(document.querySelectorAll("[data-option-flag-img-name]")).forEach(function (item) {
                var flagImg = getComputedStyle(item.querySelector(".flag-input")).backgroundImage;
                var countryFlagImg = flagImg.substring(
                    flagImg.indexOf("/as") + 1,
                    flagImg.lastIndexOf('"')
                );
                Array.from(item.querySelectorAll(".dropdown-menu li")).forEach(function (subitem) {
                    var optionFlagImg = subitem.querySelector(".options-flagimg").getAttribute("src");
                    subitem.addEventListener("click", function () {
                        var optionCountryName = subitem.querySelector(".country-name").innerHTML;
                        item.querySelector(".flag-input").style.backgroundImage = "url(" + optionFlagImg + ")";
                        item.querySelector(".flag-input").value = optionCountryName;
                    });
                    if (countryFlagImg == optionFlagImg) {
                        subitem.classList.add("active");
                        item.querySelector(".flag-input").value = subitem.querySelector(".country-name").innerHTML;
                    }
                });
            });
            // data option flag img with name
            Array.from(document.querySelectorAll("[data-option-flag-name]")).forEach(function (item) {
                var countryName = item.querySelector(".flag-input").value;
                Array.from(item.querySelectorAll(".dropdown-menu li")).forEach(function (subitem) {
                    var optionCountryName = subitem.querySelector(".country-name").innerHTML;
                    subitem.addEventListener("click", function () {
                        item.querySelector(".flag-input").value = optionCountryName;
                    });
                    if (countryName == optionCountryName) {
                        subitem.classList.add("active");
                        item.querySelector(".flag-input").value = subitem.querySelector(".country-name").innerHTML;
                    }
                });
            });
        }
        loadCountryListData(this.countrylist);
        var countryListData = this.countrylist;
        Array.from(document.querySelectorAll("[data-input-flag]")).forEach(function (item) {
            var searchInput = item.querySelector(".search-countryList");
            if (searchInput) {
                searchInput.addEventListener("keyup", function () {
                    var inputVal = searchInput.value.toLowerCase();
                    function filterItems(arr, query) {
                        return arr.filter(function (el) {
                            return (el.countryName.toLowerCase().indexOf(query.toLowerCase()) !== -1 || el.countryCode.indexOf(query) !== -1);
                        });
                    }
                    var filterData = filterItems(countryListData, inputVal);
                    setTimeout(function () {
                        item.querySelector(".dropdown-menu-list").innerHTML = '';
                        Array.from(filterData).forEach(function (listData) {
                            item.querySelector(".dropdown-menu-list").innerHTML +=
                                '<li class="dropdown-item d-flex">\
                                        <div class="flex-shrink-0 me-2"><img src="'+ listData.flagImg + '" alt="country flag" class="options-flagimg" height="20"></div>\
                        <div class="flex-grow-1">\
                        <div class="d-flex"><div class="country-name me-1">'+ listData.countryName + '</div><span class="countrylist-codeno text-muted">' + listData.countryCode + '</span></div>\
                        </div>\
                        </li>';
                        });
                        countryListClickEvent(item);
                    }, 350);
                });
            }
        });

    },
    getStatusColor(status) {
        let response = {};
        switch (status) {
            case "pending":
                response.color = 'warning';
                response.status = 'En attente';
                break;
            case "delivering":
                response.color = 'info';
                response.status = 'Livraison en cours';
                break;

            case "accepted":
                response.color = 'success';
                response.status = 'Accepté';
                break;
            case "transferred":
            case "approved":
                response.color = 'success';
                response.status = 'Approuvé';
                break;
            case "failed":
                response.color = 'danger';
                response.status = 'Échouée';
                break;
            case "refused":
                response.color = 'error';
                response.status = 'Refusée';
                break;
            case "rejected":
                response.color = 'danger';
                response.status = 'Rejetée';
                break;
                case "canceled":
                case "cancelled":
                response.color = 'danger';
                response.status = 'annulée';
                break;
                case "refunded":
                response.color = 'danger';
                response.status = 'Remboursée';
                break;
            case "delivered":
                response.color = 'success';
                response.status = 'Livré';
                break;
            case "received":
                response.color = 'success';
                response.status = 'Commande reçue';
                break;
            case "success":
                response.color = 'success';
                response.status = 'Succès';
                break;
            case "validated":
                response.color = 'success';
                response.status = 'Validé';
                break;
            case "completed":
                response.color = 'info';
                response.status = 'Complète';
                break;
            default:
                response.color = 'default';
                response.status = '--';
                break;
        }
        return response;
    },

    formatAmount(amount) {
        return new Intl.NumberFormat().format(amount);
    },
    formatDate(date, format = true) {
        try {
            return format ? new Intl.DateTimeFormat('fr-Fr', {
                    dateStyle: 'short',
                    timeStyle: 'short'
                }).format(new Date(date))
                : new Intl.DateTimeFormat('fr-Fr', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                }).format(new Date(date));
        }catch (error){
            return 'invalid date'
        }


    },
    showToast(type, message) {
        let vueBOx = this,
            Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (t) => {
                    t.addEventListener('mouseenter', vueBOx.$swal.stopTimer)
                    t.addEventListener('mouseleave', vueBOx.$swal.resumeTimer)
                }
            });
        Toast.fire({
            icon: type,
            title: message
        })
    },
    momentJs(data) {
        var date = new Date((data));
        return moment(date).startOf('hour').fromNow();
    },
    limitText: function (text, length) {
        if (text) {
            let mytext = text.slice(0, length);
            (text.length > 12) ? mytext = mytext + ' ...' : '';
            return mytext;
        } else {
            return '...';
        }
    },
    userHasPermission(permission) {
        return this.$store.getters["auth/user"] && this.$store.getters["auth/user"].permissions.includes(permission.toString())
    },
    can(permission) {
        let permissions = this.$store.getters["auth/user"]?.permissions;
        return permissions?.includes(permission.toString())
    },
    getConnected() {
        if (this.$store.getters["auth/user"])
            return this.$store.getters["auth/user"].data.identity;
    },
    getUserFullRole() {
        if (this.$store.getters["auth/user"])
            return this.$store.getters["auth/user"].data.roles[0]
    },
    getUserRole() {
        if (this.$store.getters["auth/user"])
            return this.$store.getters["auth/user"].data.roles[0].name
    },
    getUserCity() {
        if (this.$store.getters["auth/user"]){
            return this.$store.getters["auth/user"].city;
        }

    },
    getUserMerchants() {
        if (this.$store.getters["auth/user"]) {
            return this.$store.getters["auth/user"].staff.merchants
        }
    }
}
