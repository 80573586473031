import {destroy, edit, store, validateProduct} from "@/services/resources";
import {POPOTE_IMAGES, PRODUCTS} from "@/services/apis";

    export const  state = {
        editData:undefined,
    }
    export  const mutations =  {
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    }
    export  const  actions =  {
        edit({commit},id){
            return new Promise((resolve,reject)=>{
                edit(PRODUCTS,id).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        editMerchantProduct({commit},id){
            return new Promise((resolve,reject)=>{
                edit('merchant-products',id).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        search(_,data){
            return new Promise((resolve,reject)=>{
                store('find-product',data).then((response)=>{
                    //commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        searchMany(_,data){
            return new Promise((resolve,reject)=>{
                store('search-products',data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        validate(_,data){
            return new Promise((resolve,reject)=>{
                validateProduct(data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        destroyImage(_,itemId){
                return new Promise((resolve,reject)=>{
                    destroy(POPOTE_IMAGES,itemId).then((response)=>{
                        resolve(response)
                    }).catch((error)=>{
                        reject(error)
                    })
                })
            },
        }
     export const getters =  {

    }

