/*eslint-disable*/
import moment from "moment";
moment.locale("fr");
export default function (){
    return{
        data(){
            return {
                isMobile: false,
                fileSize:undefined,
                previewedImage:'',
                globalRules:{
                    string: v => !!v && typeof v=== 'string' ||  "Valeur invalid",
                    number: v => !isNaN(v) ||  "Valeur invalid",
                    positive: v => !isNaN(v) && v>0 ||  "La valeur de ce champ doit être supérieur à zéro",
                    required: v => !!v  ||  "Ce champ est requis",
                }
            }
        },
        created() {
            this.checkIfMobile();
            window.addEventListener('resize', this.checkIfMobile);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.checkIfMobile);
        },
        methods: {
            checkIfMobile() {
                this.isMobile = window.innerWidth <= 768;
            },
            getUrlFromBlob(file){
                return URL.createObjectURL(file);
            },
            clearImage(){
                this.item.files = [];
            },
            triggerFileInput(input) {
                $('#' + input).click()
            },
            filesChange() {
                //console.log( this.$refs.images.files)
                this.item.files = this.$refs.images.files;
            },
            fileChange(ref) {
                this.item[ref] = this.$refs[ref].files[0];
                if (this.item[ref]){
                    this.previewedImage = URL.createObjectURL(this.item[ref]);
                }
            },
            hasError(input){
                return this.validationErrors && this.validationErrors[input]
            },
            changeUserStatus(item){
                let Self = this;
                let text = item.identity.user.disabled_at == null?
                    `Êtes vous sûr de vouloir désactivé ${item.identity.firstname}`
                    :`Êtes vous sûr de vouloir activé ${item.identity.firstname}`;
                let title = item.identity.user.disabled_at == null? "Désactivation":"Activation";
                this.$swal({title:title,text:text,
                    icon:'warning',showCancelButton: true,
                    confirmButtonText: 'Continuer',cancelButtonText:"Annuler"})
                    .then((value => {
                        if (value.isConfirmed){
                            Self.$store.dispatch('staff/disableUser', item.identity.user.id).then(()=>{
                                Self.init();
                                Self.$swal(title,'Opération effectué avec succès','success')
                                Self.close();
                            }).catch((error)=>{
                                switch (error.response.status) {
                                    case 500 :
                                        Self.$swal(title,'Oups! une erreur est survenue, veuillez réssayer','error')
                                        break;
                                    case 400 :
                                        Self.$swal(title, error.response.data.message,'error')
                                        break;
                                }
                            });
                        }
                    }))
            },
            copy(text) {
                let Self = this;
                this.$copyText(text).then(function () {
                    Self.showToast("success",`${text} copié avec succès.`)
                }, function () {
                    Self.showToast("error","Echec de copie")
                })
            },
            getRoleName(name){
                let full = ""
                switch (name) {
                    case "deliverer":
                        full = "Achéteuse"
                        break;
                    case "co":
                        full = "Chef d'opération"
                        break;
                    case "admin":
                        full = "Administrateur"
                        break;
                }
                return full;
            },
            showImages(){
                const viewer = this.$el.querySelector('.images').$viewer
                viewer.show()
            },
            phoneChange(e){
                this.phoneIsValid = e.valid
                if (e.valid){
                    this.item.telephone =   e.number;
                    //this.item.country =   e.country.name;
                    //this.item.country_code =   e.country.iso2;
                }
            },
            resetFilter() {
                Object.assign(this.$data, this.$options.data())
                this.init()
            },
            close(modal = 'addModal'){
                this[modal] = false;
                this.update = false;
                this.submitting = false;
                Object.assign(this.$data, this.$options.data())
                if (this.$refs.form){
                    this.$refs.form.reset()
                }
            },

            calculateFileSize(size)
            {
                return size>0? size/1048576:0;
            },
            isValidFileSize(size)
            {
                return this.calculateFileSize(size)<=2;
            },
            formatFullDate(date){
                return new Intl.DateTimeFormat('fr-Fr', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(date));
            },
            fileIsImage(type){
                return type.includes('image')
            }

        }
    }

}
