import {create, edit} from "@/services/resources";
import {MERCHANTS} from "@/services/apis";

    export const  state = {
        editData:undefined,
    }
    export  const mutations =  {
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    }
    export  const  actions =  {
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(MERCHANTS).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},id){
            return new Promise((resolve,reject)=>{
                edit(MERCHANTS,id).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        }
     export const getters =  {

    }

