/*eslint-disable*/
export default function permission ({store, next,to}){
    let permissions = store.getters["auth/user"]?.permissions
    if(!permissions?.includes(to.meta.permission)){
       /* return next({
            name: 'login'
        })*/
    }
    return next();
}
