import store from "@/state/store";
import configRoutes from "@/router/configRoutes";
import auth from "@/router/middlewares/auth";
import PasswordReset from "@/views/account/PasswordReset.vue";
import guest from "@/router/middlewares/guest";
import deliveryRoutes from "@/router/deliveryRoutes";
import permission from "@/router/middlewares/permission";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Mot de pass oublié",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Mot de passe oublié",
      authRequired: false,
    },
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: () => import("../views/account/PasswordReset.vue"),
    meta: {
      title: "Réinitialisation du mot de passe",
      authRequired: false,
    },
  },
  {
    path: "/notifications",
    name: "notifications.index",
    meta: {
      middleware: [
        auth,
      ],
      title: "Notifications",
      authRequired: true,
    },
    component: () => import("../views/notifications/index.vue"),
  },
  {
    path:'/invitation/:token',
    name:'invitation',
    component:PasswordReset,
    meta:{
      title: "Configuration du mot de passe",
      authRequired: false,
      middleware: [
          guest
      ]
    }
  },

  {
    path: "/",
    name: "default",
    meta: {
      middleware: [auth],
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/crm/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Profile", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/profile/setting.vue")
  },
  {
    path: "/auth/signin-basic",
    name: "signin-basic",
    meta: {
      title: "Signin Basic",
      authRequired: true,
    },
    component: () => import("../views/auth/signin/basic"),
  },
  {
    path: "/auth/signin-cover",
    name: "signin-cover",
    meta: {
      title: "Signin Cover",
      authRequired: true,
    },
    component: () => import("../views/auth/signin/cover"),
  },
  {
    path: "/auth/signup-basic",
    name: "signup-basic",
    meta: {
      title: "Signup Basic",
      authRequired: true,
    },
    component: () => import("../views/auth/signup/basic"),
  },
  {
    path: "/auth/signup-cover",
    name: "signup-cover",
    meta: {
      title: "Signup Cover",
      authRequired: true,
    },
    component: () => import("../views/auth/signup/cover"),
  },
  {
    path: "/auth/reset-pwd-basic",
    name: "reset-pwd-basic",
    meta: {
      title: "Reset Password",
      authRequired: true,
    },
    component: () => import("../views/auth/reset/basic"),
  },
  {
    path: "/auth/reset-pwd-cover",
    name: "reset-pwd-cover",
    meta: {
      title: "Reset Password",
      authRequired: true,
    },
    component: () => import("../views/auth/reset/cover"),
  },
  {
    path: "/auth/create-pwd-basic",
    name: "create-pwd-basic",
    meta: {
      title: "create Password",
      authRequired: true,
    },
    component: () => import("../views/auth/create/basic"),
  },
  {
    path: "/auth/create-pwd-cover",
    name: "create-pwd-cover",
    meta: {
      title: "create Password",
      authRequired: true,
    },
    component: () => import("../views/auth/create/cover"),
  },

  {
    path: "/auth/404",
    name: "404",
    meta: {
      title: "Error 404",
      authRequired: true,
    },
    component: () => import("../views/auth/errors/404"),
  },
  {
    path: "/auth/500",
    name: "500",
    meta: {
      title: "Error 500",
      authRequired: true,
    },
    component: () => import("../views/auth/errors/500"),
  },
  {
    path: "/auth/404-basic",
    name: "404-basic",
    meta: {
      title: "Error 404",
      authRequired: true,
    },
    component: () => import("../views/auth/errors/404-basic"),
  },
  {
    path: "/auth/404-cover",
    name: "404-cover",
    meta: {
      title: "Error 404",
      authRequired: true,
    },
    component: () => import("../views/auth/errors/404-cover"),
  },

  {
    path: "/auth/logout-basic",
    name: "logout-basic",
    meta: {
      middleware: [auth],
      title: "Logout",
      authRequired: true,
    },
    component: () => import("../views/auth/logout/basic"),
  },
  {
    path: "/auth/logout-cover",
    name: "logout-cover",
    meta: {
      middleware: [auth],
      title: "Logout",
      authRequired: true,
    },
    component: () => import("../views/auth/logout/cover"),
  },
  {
    path: "/auth/success-msg-basic",
    name: "success-msg-basic",
    meta: {
      middleware: [auth],
      title: "Success Message",
      authRequired: true,
    },
    component: () => import("../views/auth/success-msg/basic"),
  },
  {
    path: "/auth/success-msg-cover",
    name: "success-msg-cover",
    meta: {
      middleware: [auth],
      title: "Success Message",
      authRequired: true,
    },
    component: () => import("../views/auth/success-msg/cover"),
  },

  //staff
  {
    path: "/staff",
    name: "staff.index",
    meta: {
      middleware: [auth],
      title: "Utilisateur",
      authRequired: true,
    },
    component: () => import("../views/accounts/staff/index.vue"),
  },
  {
    path: "/hos",
    name: "hos.index",
    meta: {
      middleware: [
        auth,
      ],
      title: "Chefs d'opérations", authRequired: true,
    },
    component: () => import('../views/accounts/hos/index.vue')
  },
  {
    path: "/merchant-admins",
    name: "merchant-admins.index",
    meta: {
      middleware: [
        auth,
      ],
      title: "Admin marchand", authRequired: true,
    },
    component: () => import('../views/accounts/merchant-admins/index.vue')
  },
  {
    path: "/buyer-managers",
    name: "buyer-managers.index",
    meta: {
      middleware: [
        auth,
      ],
      title: "Responsable acheteurs", authRequired: true,
    },
    component: () => import('../views/accounts/buyer-managers/index.vue')
  },
  {
    path: "/deliverers",
    name: "deliverers.index",
    meta: {
      middleware: [
        auth,
      ],
      title: "Livreurs", authRequired: true,
    },
    component: () => import('../views/accounts/deliverers/index.vue')
  },
  {
    path: "/deliverers/:id",
    name: "deliverers.show",
    meta: {
      middleware: [
        auth,
      ],
      title: "Livreurs", authRequired: true,
    },
    component: () => import('../views/accounts/deliverers/show.vue')
  },
  {
    path: '/customers',
    name: 'customers.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Clients", authRequired: true,
    },
    component: () => import('../views/accounts/customers/index.vue')
  },
  {
    path: '/customers/:id',
    name: 'customers.show',
    meta: {
      middleware: [
        auth,
      ],
      title: "Clients", authRequired: true,
    },
    component: () => import('../views/accounts/customers/show.vue')
  },
  {
    path: '/configs',
    name: 'configs.index',
    meta: {
      middleware: [auth],
      title: "Chefs d'opérations",
      authRequired: true,
    },
    component: () => import("../views/accounts/hos/index.vue"),
  },
  //merchants
  {
    path: "/merchants",
    name: "merchants.index",
    meta: {
      middleware: [auth],
      title: "Marchands",
      authRequired: true,
    },
    component: () => import("../views/accounts/merchants/index.vue"),
  },
  {
    path: "/orders",
    name: "orders.index",
    meta: {
      middleware: [auth],
      title: "Commandes",
      authRequired: true,
    },
    component: () => import("../views/orders/index.vue"),
  },
  {
    path: "/orders/:id",
    name: "orders.show",
    meta: {
      middleware: [auth],
      title: "Détails Commandes",
      authRequired: true,
    },
    component: () => import("../views/orders/show.vue"),
  },
  {
    path: "/order-chat",
    name: "orders.chat",
    meta: {
      middleware: [auth],
      title: "Discussion",
      authRequired: true,
    },
    component: () => import("../views/orders/OrderChat.vue"),
  },
  {
    path: "/customer-service",
    name: "customer-service.chat",
    meta: {
      middleware: [auth],
      title: "Discussion",
      authRequired: true,
    },
    component: () => import("../views/customer-service/CustomerServiceChat.vue"),
  },
  //subscriptions
  {
    path: "/subscriptions",
    name: "subscriptions.index",
    meta: {
      middleware: [auth],
      title: "Forfaits",
      authRequired: true,
    },
    component: () => import("../views/subscriptions/index.vue"),
  },
  {
    path: "/subscriptions/:id",
    name: "subscriptions.show",
    meta: {
      middleware: [auth],
      title: "Détail Pack",
      authRequired: true,
    },
    component: () => import("../views/subscriptions/show.vue"),
  },
  //clients-subscriptions
  {
    path: "/clients-subscriptions",
    name: "clients.subscriptions.index",
    meta: {
      middleware: [auth],
      title: "Abonnements",
      authRequired: true,
    },
    component: () => import("../views/subscriptions/clients-subscriptions.vue"),
  },
  //promo-codes
  {
    path: "/promo-codes",
    name: "promoCodes.index",
    meta: {
      middleware: [auth],
      title: "Bon d'achats",
      authRequired: true,
    },
    component: () => import("../views/promo-codes/index.vue"),
  },
  {
    path: '/promo-codes/:id',
    name: 'promo-codes.show',
    meta: {
      middleware: [
        auth,
      ],
      title: "Clients", authRequired: true,
    },
    component: () => import('../views/promo-codes/show.vue')
  },
  {
    path: "/configs",
    name: "configs.index",
    meta: {
      middleware: [auth],
      title: "Configurations",
      authRequired: true,
    },
    component: () => import("../views/configs/index.vue"),
  },
  {
    path: '/partners',
    name: 'partners.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Partenaires", authRequired: true,
    },
    component: () => import('../views/partners/index.vue')
  },
  {
    path: '/recharge-requests',
    name: 'recharge-requests.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Demandes de recharge", authRequired: true,
    },
    component: () => import('../views/recharge-requests/index.vue')
  },
  {
    path: '/withdraw-requests',
    name: 'withdraw-requests.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Demandes de virement", authRequired: true,
    },
    component: () => import('../views/withdraw-requests/index.vue')
  },
  {
    path: '/recharges',
    name: 'recharge.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Recharges", authRequired: true,
    },
    component: () => import('../views/recharges/index.vue')
  },
  {
    path: '/delivery-schedules',
    name: 'delivery-schedules.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Horaires de livraison", authRequired: true,
    },
    component: () => import('../views/configs/delivery-schedules/index.vue')
  },
  {
    path: '/product-categories',
    name: 'product-categories.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Catégories", authRequired: true,
    },
    component: () => import('../views/catalogue/product-categories/index.vue')
  },
  {
    path: '/products',
    name: 'products.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Produits", authRequired: true,
    },
    component: () => import('../views/catalogue/products/index.vue')
  },
  {
    path: '/sponsorings',
    name: 'sponsorings.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Sponsorings", authRequired: true,
    },
    component: () => import('../views/catalogue/sponsorings/index.vue')
  },
  {
    path: '/market/:client_id/:city_id',
    name: 'market.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Market", authRequired: true,
    },
    component: () => import('../views/market/index.vue')
  },
  {
    path: '/merchant-products',
    name: 'merchant-products.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Produits", authRequired: true,
    },
    component: () => import('../views/catalogue/products/merchant-index.vue')
  },
  {
    path: '/products/create/:image?',
    name: 'products.create',
    meta: {
      middleware: [
        auth,
      ],
      title: "Nouveau produit", authRequired: true,
    },
    component: () => import('../views/catalogue/products/create.vue')
  },
  {
    path: '/products/merchant/create',
    name: 'merchant-products.create',
    meta: {
      middleware: [
        auth,
      ],
      title: "Nouveau produit", authRequired: true,
    },
    component: () => import('../views/catalogue/products/merchant-create.vue')
  },
  {
    path: '/products/merchant/suggest',
    name: 'merchant-products.suggest',
    meta: {
      middleware: [
        auth,
      ],
      title: "Suggérer un produit", authRequired: true,
    },
    component: () => import('../views/catalogue/products/create.vue')
  },
  {
    path: '/products/merchant/requested',
    name: 'merchant-products.requested',
    meta: {
      middleware: [
        auth,
      ],
      title: "Produit suggérés", authRequired: true,
    },
    component: () => import('../views/catalogue/products/requested.vue')
  },
{
    path: '/products/:id/edit',
    name: 'products.edit',
    meta: {
      middleware: [
        auth,
      ],
      title: "Nouveau produit", authRequired: true,
    },
    component: () => import('../views/catalogue/products/create.vue')
  },
  {
    path: '/merchant-products/:id/edit',
    name: 'merchant-products.edit',
    meta: {
      middleware: [
        auth,
      ],
      title: "Mise à jour", authRequired: true,
    },
    component: () => import('../views/catalogue/products/merchant-create.vue')
  },
  {
    path: '/products/:id',
    name: 'products.show',
    meta: {
      middleware: [
        auth,
      ],
      title: "Produit", authRequired: true,
    },
    component: () => import('../views/catalogue/products/show.vue')
  },
  {
    path: '/merchants/create',
    name: 'merchants.create',
    meta: {
      middleware: [
        auth,
      ],
      title: "Nouveau Marchand", authRequired: true,
    },
    component: () => import('../views/accounts/merchants/create.vue')
  },
  {
    path: '/merchants/:id/edit',
    name: 'merchants.edit',
    meta: {
      middleware: [
        auth,
      ],
      title: "Nouveau marchand", authRequired: true,
    },
    component: () => import('../views/accounts/merchants/create.vue')
  },
  {
    path: '/gallery/create',
    name: 'gallery.create',
    meta: {
      middleware: [
        auth,
      ],
      title: "Nouvelle image", authRequired: true,
    },
    component: () => import('../views/catalogue/gallery/create.vue')
  },
  {
    path: '/gallery',
    name: 'gallery.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Galerie", authRequired: true,
    },
    component: () => import('../views/catalogue/gallery/index.vue')
  },
  {
    path: '/logs',
    name: 'logs',
    meta: {
      middleware: [
        auth,
      ],
      title: "Journal d'Activités", authRequired: true,
    },
    component: () => import('../views/logs/index.vue')
  },
  {
    path: '/notif/templates',
    name: 'notif.templates',
    meta: {
      middleware: [
        auth,
      ],
      title: "Messages de notification", authRequired: true,
    },
    component: () => import('../views/configs/notif-templates/index.vue')
  },
  {
    path: '/mesures/unit',
    name: 'mesures.unit',
    meta: {
      middleware: [
        auth,
      ],
      title: "Unité de Mesure", authRequired: true,
    },
    component: () => import('../views/configs/mesure-units/index.vue')
  },
  {
    path: '/shooting-schedules',
    name: 'shooting-schedules.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Demandes de shooting", authRequired: true,
    },
    component: () => import('../views/shooting-schedules/index.vue')
  }, {
    path: '/shooting-schedules',
    name: 'shooting-schedules.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Demandes de shooting", authRequired: true,
    },
    component: () => import('../views/shooting-schedules/index.vue')
  },
  {
    path: '/product-reductions',
    name: 'product-reductions.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Reductions", authRequired: true,
    },
    component: () => import('../views/reductions/index.vue')
  },
  {
    path: '/recipes',
    name: 'recipes.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Recettes", authRequired: true,
    },
    component: () => import('../views/recipes/index.vue')
  },
  {
    path: '/recipes/create/:id?',
    name: 'recipes.create',
    meta: {
      middleware: [
        auth,
      ],
      title: "Ajouter une Recette", authRequired: true,
    },
    component: () => import('../views/recipes/create.vue')
  },
  {
    path: '/recipes/:id',
    name: 'recipes.show',
    meta: {
      middleware: [
        auth,
      ],
      title: "Détails de la Recette", authRequired: true,
    },
    component: () => import('../views/recipes/show.vue')
  },
  {
    path: '/gift-packs',
    name: 'gift-packs.index',
    meta: {
      middleware: [
          auth,
          permission,
      ],
      permission :  'browse-gift-packs',
      title: "Pack de bons", authRequired: true,
    },
    component: () => import('../views/gift-packs/index.vue')
  },
  {
    path: '/gift-packs/:id',
    name: 'gift-packs.show',
    meta: {
      middleware: [
          auth,
          permission,
      ],
      permission :  'show-gift-packs',
      title: "Détails d'un pack", authRequired: true,
    },
    component: () => import('../views/gift-packs/show.vue')
  },

  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    meta: {
      title: "Page introuvable",
    },
    component: () => import('../views/auth/errors/404-basic.vue')
  },
  {
    path: '/advertisements',
    name: 'advertisements.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Publicités", authRequired: true,
    },
    component: () => import('../views/advertisements/index.vue')
  },
  {
    path: '/advertisements/create',
    name: 'advertisements.create',
    meta: {
      middleware: [
        auth,
      ],
      title: "Publicités Création", authRequired: true,
    },
    component: () => import('../views/advertisements/create.vue')
  },
  {
    path: '/advertisements/:id/edit',
    name: 'advertisements.edit',
    meta: {
      middleware: [
        auth,
      ],
      title: "Publicités Mise à jour", authRequired: true,
    },
    component: () => import('../views/advertisements/create.vue')
  },
  {
    path: '/advertisements/:id',
    name: 'advertisements.show',
    meta: {
      middleware: [
        auth,
      ],
      title: "Publicités Détails", authRequired: true,
    },
    component: () => import('../views/advertisements/show.vue')
  },
  {
    path: '/unavailability_requests',
    name: 'unavailability_requests.index',
    meta: {
      middleware: [
        auth,
      ],
      title: "Requêtes d'indisponibilités", authRequired: true,
    },
    component: () => import('../views/unavailability_requests/index.vue')
  },
  {
    path: '/unavailability_requests/calendar',
    name: 'unavailability_requests.calendar',
    meta: {
      middleware: [
        auth,
      ],
      title: "Calendrier d'indisponibilités", authRequired: true,
    },
    component: () => import('../views/unavailability_requests/calendar.vue')
  },
];

export default [...routes, ...configRoutes, ...deliveryRoutes];
