/*eslint-disable*/


export default function () {
    return {
        beforeUnmount(){
            this.$store.dispatch("crud/resetData")
        },
        data() {
            return {
                item: {},
                url : {page : 1},
                validationErrors: undefined,
                submitting: false,
                update: false,
            }
        },
        methods: {
            get(url) {
                if (url) {
                    return new Promise((resolve, reject) => {
                        this.$store.dispatch("crud/get", url).then((data) => {
                            resolve(data)
                        })
                    })
                } else {
                    this.init()
                }
            },
            changePage(options) {
                this.url.page = options.page;
                this.url.per_page = options.per_page;
                this.init()
            },
            search(key) {
                this.url.search = key;
                this.init()
            },
            create(resource, param = "") {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch("crud/create", resource).then((response) => {
                        resolve(response)
                    });
                })
            },
            createWithoutCommit(resource, param = "") {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch("crud/createWithoutCommit", resource).then((response) => {
                        resolve(response)
                    });
                })
            },
            show(data) {

                return new Promise((resolve,reject) => {
                    this.$store.dispatch("crud/show", data).then((response) => {
                        resolve(response)
                    });
                })

            },
            store(resource, data, message = null) {
                this.$store.dispatch("crud/store", {data: data, resource: resource}).then(() => {
                    if (this.$route.name.includes('create') || this.$route.name.includes('edit')){
                        this.$router.go(-1)
                        this.showToast('success',message || 'Ajout effectué avec succès')
                    }else{
                        this.init(this.url);
                        this.$swal('Enregistrement', message || 'Ajout effectué avec succès', 'success')
                        this.close();
                    }
                }).catch((error) => {
                    if (error.response){
                        switch (error.response.status) {
                            case 500 :
                                this.$swal('Enregistrement', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                                break;
                            case 422:
                                this.showToast('error',"Des erreurs de validations sont survenues, veuillez les corriger et resoumettre le formulaire")
                                this.validationErrors = error.response.data.errors
                                break;
                                case 406:
                                this.showToast('error',"Oups! impossible d'éffectuer cette opération")
                                this.validationErrors = error.response.data.errors
                                break;
                        }
                    }else{
                        console.error(error)
                    }

                }).finally(() => {
                    this.submitting = false;
                });
            },
            save(resource, message = null) {
                this.submitting = true;
                let data = new FormData();
                Object.keys(this.item).forEach(key => {
                    switch (key) {
                        case 'telephone':
                            let code = this.item[key].toString().includes(this.countryCode)? ''  : this.countryCode;
                            data.append(key, `${code}${this.item[key]}`)
                            break;
                        case 'details':
                            this.item[key].forEach((detail, index) => {
                                data.append(`${key}[${index}]`, detail)
                            })
                            break;
                        case 'cities':
                        case 'districts':
                        case 'deliverers':
                        case 'merchants':
                        case 'permissions':
                        case 'components':
                        case 'clients':
                        case 'targets':
                        case 'files':
                        case 'products':
                            for (const element of this.item[key]) {
                                data.append(key + '[]', element)
                            }
                            break;
                        default :
                            if (this.item[key] !== undefined && this.item[key] != null) {
                                data.append(key, this.item[key])
                            }
                            break;
                    }
                });

                if (this.update) {
                    data.append("_method", "PUT")
                }

                if (this.update) {
                    this.updateItem(resource, data);
                } else {
                    this.store(resource, data, message)
                }
            },
            updateItem(resource, data) {
                this.$store.dispatch("crud/update", {data: data, resource: resource, id: this.item.id}).then(() => {
                    if (this.$route.name.includes('create') || this.$route.name.includes('edit')){
                        this.$router.go(-1)
                        this.showToast('success','Mise à jour effectué avec succès')
                    }else{
                        this.init(this.url);
                        this.$swal('Mise à jour', 'Mise à jour effectué avec succès', 'success')
                        this.close();
                    }
                }).catch((error) => {
                    if (error.response){
                        switch (error.response.status) {
                            case 500 :
                                this.$swal('Mise à jour', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                                break;
                            case 422:
                                this.showToast('error',"Des erreurs de validations sont survenues, veuillez les corriger et resoumettre le formulaire")
                                this.validationErrors = error.response.data.errors
                                break;
                        }
                    }else{
                        console.error(error)
                    }

                }).finally(() => {
                    this.submitting = false;
                });
            },
            edit(item) {
                if (item.telephone)
                {
                    this.item.telephone = item.telephone.toString().substring(4)
                }
                this.item = {...item}
                this.addModal = true;
                this.update = true;
                if (this.item.begin_at && this.rangeDateconfig) {
                    this.item.period = item.begin_at.toString() + " to " + item.end_at.toString();
                }

            },
            destroy(item, resource, msg = "Suppression effectuée avec succès") {
                this.$swal({
                    title: 'Suppression', text: `Etes vous sûr(e) de vouloir supprimé ce element?`,
                    icon: 'warning', showCancelButton: true,
                    confirmButtonText: 'Oui,supprimer', cancelButtonText: "Annuler"
                })
                    .then((value => {
                        if (value.isConfirmed) {
                            this.$store.dispatch('crud/destroy', {id: item.id, resource: resource}).then(() => {
                                this.get(this.url);
                                this.$swal('Suppression', msg, 'success')
                                this.close();
                            }).catch((error) => {
                                switch (error.response.status) {
                                    case 500 :
                                        this.$swal('Supression', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                                        break;
                                    case 409 :
                                        this.$swal('Conflit', error.response.data.message)
                                        break;
                                }
                            });
                        }
                    }))
            },
            editItemAction(item, index) {
                switch (index) {
                    case 0:
                        this.edit(item);
                        break;
                    case 1:
                        this.$router.push({name: 'cities.show', params: {id: item.id}})
                        break;
                    case 2:
                        this.destroy(item)
                        break;

                }
            }
        }
    }
}
