import { dashboard as getDashboardData,create,store} from "@/services/resources";
import {DASHBOARD,} from "@/services/apis";


export const state = {
    dashboardNumberStats:undefined,
    filterData:undefined,
    recharges: undefined,

};

export const getters = {
    todos: state => state.todos,
};

export const mutations = {
    SET_DASHBOARD_DATA(state,payload){
        state.dashboardNumberStats = payload
    },
    SET_FILTER_DATA(state,payload){
        state.filterData = payload
    },
    SET_RECHARGES(state,payload){
        state.recharges = payload
    },
};

export const actions = {
    get({commit},data){
        return new Promise((resolve,reject)=>{
            getDashboardData(data).then((response)=>{
                commit('SET_DASHBOARD_DATA',response.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    create({commit}){
        return new Promise((resolve,reject)=>{
            create(DASHBOARD).then((response)=>{
                commit('SET_FILTER_DATA',response.data)
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    rechargeWallet(_,data){
        return new Promise((resolve,reject)=>{
            store("bonus-wallet/recharge",data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getRecharges({commit},data){
        return new Promise((resolve,reject)=>{
            store("get-popote-recharges",data).then((response)=>{
                commit('SET_RECHARGES',response.data)
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

};
