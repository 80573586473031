<template>
  <b-row>
    <b-col lg="12">
      <div class="text-center mt-sm-5 mb-4 text-white-50">
        <div>
          <router-link to="/" class="d-inline-block auth-logo">
<!--            <img src="@/assets/images/logo-light.png" alt="" height="20" />-->
          </router-link>
          <p class="mt-3 fs-15 fw-medium">
            Administration <strong> Popote</strong>
          </p>
        </div>
      </div>
    </b-col>
  </b-row>

</template>
<script>
export default {
  name: 'WelcomTitleLogin'
}
</script>