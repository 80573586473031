import client from "@/state/axios/client";

    export const  state = {
        categories:undefined,
        products:undefined,
    }
    export  const mutations =  {
        SET_CATEGORIES(state,payload){
            state.categories = [...payload]
        },

        SET_PRODUCTS(state,payload){
            state.products = {...payload}
        },
    }
    export  const  actions =  {
        getCategories({commit}){
            return new Promise((resolve,reject)=>{
                client({
                    method : 'GET',
                    url: 'v1/products-categories',
                }).then(response => {
                    commit('SET_CATEGORIES',response.data)
                    resolve(response)
                }).catch((error)=>reject(error.response))
            })
        },
        getProducts({commit},data){
            return new Promise((resolve,reject)=>{
                client({
                    method : 'GET',
                    url: `v1/products/${data.category_id}/${data.city_id}`,
                }).then(response => {
                    commit('SET_PRODUCTS',response.data)
                    resolve(response)
                }).catch((error)=>reject(error.response))
            })
        },

    }
     export const getters =  {

    }

