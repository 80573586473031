export const LOGIN = "login"//"oauth/token";
export const VERIFY = "users/verify/";
export const FIND_TOKEN = "forgot-password/";
export const RESET_PASSWORD = "reset-password";
export const PASSWORD_FORGOT = "forgot-password";
export const LOGOUT = "logout";
export const GET_USER_INFOS = "login";
export const CITIES = "cities"
export const DISTRICTS = "districts"
export const STAFF = "staff"
export const CART_CATEGORIES = "products-categories"
export const CART_PRODUCTS = "staff"
export const DELIVERERS = "deliverers"
export const HOS = "hos"
export const PRODUCTS_CATEGORIES = "product-categories"
export const SEARCH_KEYS = "search-keys"
export const MERCHANTS = "merchants"
export const PRODUCTS = "products"
export const POPOTE_IMAGES = "popote-images"
export const STOCKS = "stocks"
export const PROMO_CODES = "promo-codes"
export const PARTNERS = "partners"
export const ADVERTISEMENTS = "advertisements"
export const ORDERS = "orders"
export const CLIENTS = "clients"
export const DASHBOARD = "dashboard"
export const CONFIGS = "configs"
export const WITHDRAWAL_FEES = "withdrawal-fees"
export const GSM_PROVIDERS = "gsm-providers"
export const RECHARGE_REQUESTS = "recharge-requests"
export const NOTIFICATIONS = "notifications"
export const POLITICS = "politics"
