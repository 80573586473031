<template>
  <footer class="footer">
    <b-container>
      <b-row>
        <b-col lg="12">
          <div class="text-center">
            <p class="mb-0 text-muted">
              &copy; 2022 All rights reserved | Popote Group
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
<script>
export default {
  name: 'AuthFooter'
}
</script>